import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledTypography = styled(Typography)({
    color: 'white',
    marginBottom: '8px',
    fontSize: '18px',
});

const offices = [
    {
        name: "Lagos Office",
        address: "XYZ Street, Lagos",
        phone: "+234 333 222 111",
        email: "lagos.tefuoffice@gmail.com"
    },
    {
        name: "Abuja Office",
        address: "ABC Street, Abuja",
        phone: "+234 333 222 111",
        email: "abuja.tefuoffice@gmail.com"
    },
    {
        name: "Kano Office",
        address: "PQR Street, Kano",
        phone: "+234 333 222 111",
        email: "kano.tefuoffice@gmail.com"
    }
];

const ContactDetails = () => {
    const [selectedOffice, setSelectedOffice] = useState(null);

    const handleClick = (index) => {
        setSelectedOffice(selectedOffice === index ? null : index);
    };
 
    return (
        <Container sx={{ backgroundColor: 'gray', py: 6 }}
        data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Grid container spacing={2}>
                {offices.map((office, index) => (
                    <Grid item xs={12} key={index}>
                        <Box onClick={() => handleClick(index)} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                            <StyledTypography sx={{ fontSize: '24px', marginRight: '8px' }}>
                                {office.name}
                            </StyledTypography>
                            <ExpandMoreIcon sx={{ color: 'white' }} />
                        </Box>
                        {selectedOffice === index && (
                            <>
                                <Divider sx={{ backgroundColor: 'white', my: 2 }} />
                                <StyledTypography>Address: {office.address}</StyledTypography>
                                <StyledTypography>Phone: {office.phone}</StyledTypography>
                                <StyledTypography>Email: {office.email}</StyledTypography>
                            </>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ContactDetails;