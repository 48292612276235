import React from 'react';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DrawerList from './DrawerList';
import logo from '../../../assets/logo.png';
import { useHistory } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '8px 16px',
    border: 'none',
    boxShadow: 'none',
});

const StyledStack = styled(Stack)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
});

const BoxMenus = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const DrawerBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

export default function AppBarMain() {
    const history = useHistory();
    
    const onHandleRedirect = () =>{
        history.go(-1);
    }
    return (
        <AppBar position="static" style={{ backgroundColor: 'white' }}>
            <StyledToolbar>
                <StyledStack direction="row" spacing={1}>
                    <Box onClick={onHandleRedirect} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <img
                            src= {logo}
                            height="65"
                            width="160"
                            alt=""
                        />
                    </Box>
                </StyledStack>
                <BoxMenus display="flex" alignItems="center" gap={4} p={2}>
                    <Typography color = "primary" sx = {{fontWeight: 'bold' }} variant="body1" component="p" style={{ cursor: 'pointer' }}>
                        INVEST
                    </Typography>
                    <Typography color = "primary" sx = {{fontWeight: 'bold' }} variant="body1" component="p" style={{ cursor: 'pointer' }}>
                        RESOURCES
                    </Typography>
                    <Typography color = "primary" sx = {{fontWeight: 'bold' }} variant="body1" component="p" style={{ cursor: 'pointer' }}>
                        ABOUT-US
                    </Typography>
                    <Typography color = "primary" sx = {{fontWeight: 'bold' }} variant="body1" component="p" style={{ cursor: 'pointer' }}>
                        CONTACT
                    </Typography>
                </BoxMenus>
                    <DrawerBox>
                        <DrawerList />
                    </DrawerBox>
            </StyledToolbar>
        </AppBar>
    );
}
