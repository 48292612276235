// KYCForm.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Box, Button, Typography, CssBaseline } from '@mui/material';
import FormIdentityDetails from './FormIdentityDetails';
import FormAddressDetails from './FormAddressDetails';

const KYCForm = () => {
  const history = useHistory();

  const [identityDetails, setIdentityDetails] = useState({
    firstName: '',
    lastName: '',
    fathersName: '',
    gender: '',
    guarantorName: '',
    guarantorPhone: '',
    guarantorAddress: '',
    guarantorName1: '',
    guarantorPhone1: '',
    guarantorAddress1: '',
    guarantorName2: '',
    guarantorPhone2: '',
    guarantorAddress2: '',
    maritalStatus: '',
    dob: '',
    nationality: '',
    status: '',
    pan: '',
    proofIdentity: '',
  });

  const [addressDetails, setAddressDetails] = useState({
    correspondenceAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    nin: '',
    phoneNumber: '',
    email: '',
    proofAddress: '',
    permanentAddress: '',
  });

  const handleContinue = () => {
    if (validateForm()) {
      history.push('/account-details');
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const validateForm = () => {
    return (
      identityDetails.firstName !== '' &&
      identityDetails.lastName !== '' &&
      addressDetails.correspondenceAddress !== '' &&
      addressDetails.city !== ''
    );
  };

  return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            KYC Registration - Identity and Address Details
          </Typography>
          <Box sx={{ mt: 3 }}>
            <FormIdentityDetails formData={identityDetails} setFormData={setIdentityDetails} />
            <FormAddressDetails formData={addressDetails} setFormData={setAddressDetails} />
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default KYCForm;
