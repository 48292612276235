import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const HeaderWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '8px 16px',
    width: '100%',
});

const AppBarHeader = () => {
    const theme = useTheme();

    return (
        <HeaderWrapper sx={{
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            color: theme.palette.secondary.main,
        }}>
            <Stack direction="row" spacing={2}>
                <TwitterIcon color="secondary" sx={{ fontSize: '1.3rem', }} />
                <FacebookIcon color="secondary" sx={{ fontSize: '1.3rem', }} />
                <InstagramIcon sx={{ color: theme.palette.secondary.main, fontSize: '1.3rem', }} />
                <LinkedInIcon sx={{ color: theme.palette.secondary.main, fontSize: '1.3rem', }} />
                <YouTubeIcon sx={{ color: theme.palette.secondary.main, fontSize: '1.3rem', }} />
            </Stack>
            <Stack direction="row" spacing={3}>
                <Typography variant="body2">CLIENT LOGIN</Typography>
                <Typography variant="body2">CAREERS</Typography>
                <Typography variant="body2">REPORT AN ABUSE</Typography>
                <Typography variant="body2">CONTACT US</Typography>
            </Stack>
        </HeaderWrapper>
    );
};

export default AppBarHeader;
