// FormAddressDetails.js
import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const FormAddressDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography component="h2" variant="h6">
        Address Details
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="correspondenceAddress"
        label="Address for Correspondence"
        name="correspondenceAddress"
        autoComplete="address-line1"
        value={formData.correspondenceAddress}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="city"
        label="City"
        name="city"
        autoComplete="address-level2"
        value={formData.city}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="state"
        label="State"
        name="state"
        autoComplete="address-level1"
        value={formData.state}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="zipCode"
        label="ZIP / Postal Code"
        name="zipCode"
        autoComplete="postal-code"
        value={formData.zipCode}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="country"
        label="Country"
        name="country"
        autoComplete="country"
        value={formData.country}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="nin"
        label="NIN Number"
        name="nin"
        autoComplete="nin"
        value={formData.nin}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="phoneNumber"
        label="Phone Number"
        name="phoneNumber"
        autoComplete="tel"
        value={formData.phoneNumber}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="proofAddress"
        label="Proof of Address"
        name="proofAddress"
        value={formData.proofAddress}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        fullWidth
        id="permanentAddress"
        label="Permanent Address"
        name="permanentAddress"
        autoComplete="address-line2"
        value={formData.permanentAddress}
        onChange={handleChange}
      />
    </Box>
  );
};

export default FormAddressDetails;
