// import { PlaceOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const StyledTypography = styled(Typography)({
    fontSize: '44px',
    fontWeight: '600'
})

const AboutUsSection = () => {
    

    return ( 
        <>
            <Container data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <Stack
                    direction={{ sm: 'column', md: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 8 }}
                    sx={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                    <Box>
                        <Box sx={{ borderRadius: '30px', overflow: 'hidden' }}>
                            <img
                                src="https://media.istockphoto.com/id/1457433817/photo/group-of-healthy-food-for-flexitarian-diet.jpg?b=1&s=612x612&w=0&k=20&c=V8oaDpP3mx6rUpRfrt2L9mZCD0_ySlnI7cd4nkgGAb8="
                                height="310px"
                                width="420px"
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ marginTop: '10px' }}>
                           <StyledTypography color="primary">ABOUT US</StyledTypography>
                            <Typography variant="h6" component="p">TEFU Investment Management Software is a comprehensive, ethical investment platform developed by TEFU, a leader in non-interest finance. Our software is designed to meet the needs of ethically-conscious investors, providing advanced tools and features to manage investment portfolios in strict adherence to Shariah principles and other ethical guidelines.</Typography>
                                <Button variant="outlined" color="primary" size="small" sx={{ marginTop: '15px' }}>READ-MORE</Button>
                            <Stack direction="row" spacing={5} sx={{ marginTop: '15px' }}>
                <TwitterIcon color="primary" sx={{ fontSize: '2rem', }} />
                <FacebookIcon color="primary" sx={{ fontSize: '2rem', }} />
                <InstagramIcon color="primary" sx={{ fontSize: '2rem', }} />
                <LinkedInIcon color="primary" sx={{ fontSize: '2rem', }} />
                <YouTubeIcon color="primary" sx={{ fontSize: '2rem', }} />
            </Stack>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </>
     );
}
 
export default AboutUsSection;