import * as React from 'react';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { styled } from '@mui/material/styles';

const StyledMenuIcon = styled(MenuRoundedIcon)({
    fontSize: '2rem',
    color: 'black' 
});

export default function TemporaryDrawer() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleLinkClick = () => {
        setOpen(false);
    };

    const home = (
            <Typography variant="body1" component="p" style={{ cursor: 'pointer' }}>HOME</Typography>
    );
    
    const about = (
            <Typography variant="body1" component="p" style={{ cursor: 'pointer' }}>ABOUT-US</Typography>
    );

    const contact = (
            <Typography variant="body1" component="p" style={{ cursor: 'pointer' }}>CONTACT-US</Typography>
    );

    

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={handleLinkClick}>
            <List>
                {[home, about, contact].map((text, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <div>
            <Button onClick={toggleDrawer(true)}><StyledMenuIcon /><Typography variant="body1" component="p">
                Menu
            </Typography></Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}
