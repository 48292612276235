import { Box, Container, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const CenteredBox = styled(Stack)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const InvestorNavSection = () => {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 8, mt: -16, position: 'relative', zIndex: 2 }}
        data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.primary.main, width: '65%', p: 4 }}>
                <CenteredBox 
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={4}
                >
                    <Box sx={{ textAlign: 'center', width: { xs: '100%', md: 'auto' } }}>
                        <Typography variant="h5" color="secondary"><b>Click On The Profile That Best Describes You</b></Typography>
                    </Box>
                        <Box sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: 'auto' } }}>
                            <PersonIcon color="secondary" sx={{ fontSize: '6.3rem' }} />
                            <Typography variant="body1" color="secondary"><b>Individual Investor</b></Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: { xs: '100%', md: 'auto' } }}>
                            <ApartmentIcon color="secondary" sx={{ fontSize: '6.3rem' }} />
                            <Typography variant="body1" color="secondary"><b>Institutional Investor</b></Typography>
                        </Box>
                </CenteredBox>
            </Container>
        </Box>
    );
}

export default InvestorNavSection;
