import { Box, Stack, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import AppleIcon from '@mui/icons-material/Apple';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import appSample from '../../assets/phone.jpg';

const StyledTypography = styled(Typography)({
    fontSize: '44px',
    fontWeight: '600'
})

const BlackBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: "black",
    padding: "10px",
})

const AppDownloadSection = () => {
    

    return ( 
        <>
            <Container data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
                <Stack
                    direction={{ sm: 'column', md: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 8 }}
                    sx={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                    <Box>
                        <Box sx={{ borderRadius: '30px', overflow: 'hidden' }}>
                            <img
                                src={appSample}
                                 height="510px"
                                width="400px"
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ marginTop: '10px' }}>
                           <StyledTypography>Download the</StyledTypography>
                           <StyledTypography color="primary">TEFU INVESTMENT</StyledTypography>
                           <StyledTypography>Mobile App</StyledTypography>
                            <Stack direction="row" spacing={5} sx={{ marginTop: '15px' }}>
               <BlackBox>
                    <PlayArrowIcon color = "secondary" sx = {{fontSize: "3rem"}} />
                    <Box>
                        <Typography variant = "body1" color= "secondary">Get it on</Typography>
                        <Typography variant = "h5" color= "secondary">Google Play</Typography>
                    </Box>
               </BlackBox>
               <BlackBox>
                    <AppleIcon color = "secondary" sx = {{fontSize: "3rem"}} />
                    <Box>
                        <Typography variant = "body1" color= "secondary">Download on the</Typography>
                        <Typography variant = "h5" color= "secondary">App Store</Typography>
                    </Box>
               </BlackBox>
            </Stack>
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </>
     );
}
 
export default AppDownloadSection;