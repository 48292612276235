import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoginPage from "./components/Authentication/LoginPage";
import KYCForm from "./components/Authentication/KYCForm";
import SuccessPage from "./components/Authentication/SuccessPage";
import FormRegistration from './components/Authentication/FormRegistration';
import FormAccountDetails from './components/Authentication/FormAccountDetails';
import HomePage from "./components/homePage/HomePage";
import NavBar from "./components/navBar/NavBar";
// adding animation on scroll
import { useEffect } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, []);

  return (
    <div>
      <Router>
      <NavBar />
      <Switch>
         <Route exact path='/'>
      <HomePage />
      </Route>
       <Route exact path="/login" component={LoginPage} />
        <Route path="/kyc-form" component={KYCForm} />
        <Route path="/account-details" component={FormAccountDetails} />
        <Route path="/register-form" component={FormRegistration} />
        <Route path="/success" component={SuccessPage} />
      </Switch>
      </Router>
    </div>
  );
};

export default App;
