import { Link } from 'react-router-dom';


import { PlaceOutlined } from '@mui/icons-material';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import img from '../../assets/girlSmile.png';

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
    },
}));

const SectionOne = () => {
    return (
        <Box sx={{ 
            backgroundColor: '#FFE6F7',
             width: '100%', position: 'relative', zIndex: 1 }} 
             data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 8 }} id="SectionOne" >
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={4}
                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
                >
                    <Box sx={{ flex: 1, mt: { xs: 4, md: 12 } }}>
                        <StyledTypography color="primary">Earn profit without compromising your values</StyledTypography>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Open a TEFU <b>Halal Fixed Income Fund</b> Account</Typography>
                            <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                                <Link to = "/login">
                                    <Button variant="outlined" startIcon={<PlaceOutlined />} size="small">CLIENT LOGIN</Button>
                                </Link>
                                <Typography variant="h5">BBB | Stable Outlook</Typography>
                                <Rating name="read-only" value={4} readOnly size="small" />
                                <Typography variant="body1">Credit Rating by Agusto & Co | June 2022</Typography>
                            </Stack>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <img src={img} height="600px" width="400px" alt="Smiling girl" />
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
};

export default SectionOne;
