// FormAccountDetails.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, CssBaseline } from '@mui/material';


const FormAccountDetails = () => {
  const history = useHistory();

  const [accountDetails, setAccountDetails] = useState({
    accountNumber: '',
    bvn: '',
    bankName: '',
    // Add more fields as needed
  });

  const handleFinish = () => {
    // Perform form validation before submission
    if (validateForm()) {
      // Store data to local storage or perform API call
      localStorage.setItem('accountDetails', JSON.stringify(accountDetails));
      history.push('/register-form');
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const validateForm = () => {
    // Example validation logic, add your own as needed
    return accountDetails.accountNumber !== '' && accountDetails.bvn !== '' && accountDetails.bankName !== '';
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Account Details Form
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="accountNumber"
              label="Account Number"
              name="accountNumber"
              autoComplete="account-number"
              value={accountDetails.accountNumber}
              onChange={(e) =>
                setAccountDetails({ ...accountDetails, accountNumber: e.target.value })
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="bankName"
              label="Bank Name"
              name="bankName"
              autoComplete="bank-name"
              value={accountDetails.bankName}
              onChange={(e) =>
                setAccountDetails({ ...accountDetails, bankName: e.target.value })
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="bvn"
              label="BVN"
              name="bvn"
              autoComplete="bvn"
              value={accountDetails.bvn}
              onChange={(e) => setAccountDetails({ ...accountDetails, bvn: e.target.value })}
            />
            {/* Add more form fields for account details */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleFinish}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default FormAccountDetails;
