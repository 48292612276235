import React from 'react';
import { Box } from '@mui/material';
import AppBarHeader from './appBarHeader/AppBarHeader';
import AppBarMain from './appBarHeader/AppBarMain';
import { styled } from '@mui/material/styles';

const BoxHead = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
    },
}));
const NavBar = () => {
    return (
        <>
        <div data-aos="fade-down">
            <BoxHead sx={{ width: '100%' }}>
                <AppBarHeader />
            </BoxHead>
            <Box sx={{ width: '100%' }}>
                <AppBarMain />
            </Box>
        </div>
        </>
    );
}

export default NavBar;
