import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#E59139', // Orange color
        },
        secondary: {
            main: 'rgba(255, 255, 255, 1)', // White color in RGBA
        },
        opacityColor: {
            main: 'rgba(229, 145, 57, 0.7)', // Light Orange color
        },
        otherColor: {
            main: '#000000', // Black color
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
            color: '#E59139', // Orange color
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
            color: '#E59139', // Orange color
        },
        body1: {
            fontSize: '0.875rem',
            color: 'rgba(0, 0, 0, 1)',
        },
        body2: {
            fontSize: '0.775rem',
            color: 'white',
        },
    },
});

export default theme;
