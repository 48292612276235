import { AppBar, Toolbar, Typography, Box, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Facebook from '@mui/icons-material/Facebook';
import WhatsApp from '@mui/icons-material/WhatsApp';
import Instagram from '@mui/icons-material/Instagram';
import footerLogo from '../../assets/logo.png';


const StyledStack = styled(Stack)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
})

const Footer = () => {
    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: 'rgba(255, 165, 0, 0.19)' }} color="secondary" >
                <Toolbar sx={{
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: '40px',
                    display: { xs: 'block', sm: 'block', md: 'flex' },
                }}>
                    <StyledStack direction="row" spacing={1} sx={{ mr: 4 }}>
                        <Stack direction="column">
                            <img src= {footerLogo} height="65" width="160" alt="" />
                        </Stack>
                    </StyledStack>
                    <Box  sx={{ mb: 4 }}>
                            <Typography variant="body1" component="div" sx={{ mr: 2, fontWeight: 600 }} style={{ cursor: 'pointer' }}>
                                ADDRESS LIST
                            </Typography>
                            <Box>
                                {/* location icon */}
                                <Typography variant="subtitle1" component="div" sx={{ mr: 2 }} style={{ cursor: 'pointer' }}>
                                    182, Awolowo Road, Falomo, Ikoyi, Lagos State, Nigeria.
                                </Typography>
                            </Box>
                            <Box>
                                {/* call icon */}
                                <Typography variant="subtitle1" component="div" sx={{ mr: 2 }} style={{ cursor: 'pointer' }}>
                                   09087058407 
                                </Typography>
                                <Typography variant="subtitle1" component="div" sx={{ mr: 2 }} style={{ cursor: 'pointer' }}>
                                   09087058408 
                                </Typography>
                            </Box>
                            <Box>
                                {/* mail icon */}
                                <Typography variant="subtitle1" component="div" sx={{ mr: 2 }} style={{ cursor: 'pointer' }}>
                                    
                                    info@tefuinvestment.com
                                </Typography>
                            </Box>
                    </Box>
                      <Box sx={{ mb: 4 }}>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2, fontWeight: 600 }}>
                            LINKS LIST
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2 }}>
                            Help Center
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2 }}>
                            TEfu Capital Blog
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2 }}>
                            Privacy Policy Different Dishes
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2 }}>
                            Regulatory & Disclosures
                        </Typography>
                        <Typography variant="subtitle1" component="div" sx={{ mr: 2 }}>
                            Terms of Use
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={3} sx={{ mb: 4 }}>
                        <Facebook color="primary" />
                        <WhatsApp color="primary" />
                        <Instagram color="primary" />
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Footer;
