import { Box, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Financialimg from '../../assets/girlSmile.png';

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
    },
}));

const feature1 = [
    "TEFU Financial Intelligence Corner",
    "TEFU Investor Quarterly",
    "Mutual Fund Fact Sheet",
];

const feature2 = [
    "Investment Conversation Series",
    "TEFU Personal Finance Tools",
    "TEFU Weekly Market Update",
];

const FinancialIntelligenceSection = () => {
    return (
        <Box sx={{ backgroundColor: '#FFE6F7', width: '100%', position: 'relative', zIndex: 1 }}
        data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 8 }} id="SectionOne">
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={4}
                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
                >
                    <Box sx={{ flex: 1, mt: { xs: 4, md: 12 } }}>
                        <StyledTypography color="primary">TEFU Financial Intelligence Corner</StyledTypography>
                        <Box sx={{ mt: 2 }}>
                            <Stack  direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 6 }}>
                                <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                                    {feature1.map((feature, index) => (
                                        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <CheckCircleIcon color="primary" />
                                            <Typography variant="body1">{feature}</Typography>
                                        </Box>
                                    ))}
                                </Stack>
                                <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                                    {feature2.map((feature, index) => (
                                        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <CheckCircleIcon color="primary" />
                                            <Typography variant="body1">{feature}</Typography>
                                        </Box>
                                    ))}
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <img src={Financialimg} height="600px" width="400px" alt="Smiling girl" />
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
}

export default FinancialIntelligenceSection;
