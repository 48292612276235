import { ListItemButton, Container, Stack, Typography, Paper, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import bgimg from '../../assets/building.jpg';

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
    },
}));

const data = [
    {
        title: "Nigeria’s Islamic Banking Regulation Moves Towards Global Convergence"
    },
    {
        title: "Nigeria embraces Islamic Pension Funds"
    },
    {
        title: "Islamic Commercial Papers: A gentle introduction to the corporate sector",
    },
    {
        title: "Penetration of Islamic Mutual Funds Deepens in Nigeria",
    },
];

const Wrapper = styled(Paper)(({ theme }) => ({
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'center',
     alignItems: 'center',
     padding: '10px',
    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));

const NewsAndEventSection = () => {
    return ( 
        <>
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1 }}
        data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Stack  direction= 'column'>
            <StyledTypography color="primary">News & Events</StyledTypography>
            <Grid
                container
                alignItems="center"
                spacing={2}
            >
                {data.map((item, index) => (
                    <Grid item key={index} xs={6} sm={4} md={3}>
                        <Wrapper elevation={3}>
                             <Typography variants = "body1" color = "primary" sx={{ marginTop: '10px', }}> {item.title} </Typography>
                            <Button> Read More>> </Button>
                        <ListItemButton  />
                        </Wrapper>
                    </Grid>
                ))}
            </Grid>
            </Stack>
        </Container>
        <img src={bgimg} alt="building"  width = "100%" height="250px"/>
        </>
     );
}
 
export default NewsAndEventSection;