// src/components/SuccessPage.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const SuccessPage = () => (
  <Container>
    <Box mt={5}>
      <Typography variant="h1">Welcome Back,</Typography>
      <Typography variant="body1">Here is your dashboard, coming soon . . . . !</Typography>
    </Box>
  </Container>
);

export default SuccessPage;
