import React from 'react';
import { Typography, Box, Grid, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import img from '../../assets/building.jpg';
import './InvestmentSolutionSection.css';

const SmallBox = styled(Box)(({ theme }) => ({
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.otherColor.main,
  padding: '20px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    height: '150px',
    marginBottom: theme.spacing(1),
  },
}));

const bottomBoxes = [
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU HALAL FIXED INCOME FUND" },
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU HALAL INVESTMENT FUND" },
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU WAQF (ENDOWMENT) FUND" },
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU HALAL EQUITY EXCHANGE TRADED FUND" },
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU PERSONAL FINANCE TOOLS" },
  { icon: <CreditCardIcon color="primary" sx={{ fontSize: '3rem' }} />, text: "TEFU ZAKAT CALCULATOR" },
];

const InvestmentSolutionSection = () => {
  const theme = useTheme();

  return (
    <Box className='main' sx={{ height: '100vh', width: '100%', overflow: 'hidden' }}
    data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
      <Box className='overlay' sx={{ backgroundColor: theme.palette.opacityColor.main }}></Box>
      <img src={img} alt="building" className="background-img" />
      <Box className="content">
        <Stack direction={{ sm: 'column', md: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 8 }}
        sx= {{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Box>
                <Typography variant = "h3" color = "secondary">Explore our Investment Solutions.</Typography>
                <Typography variant = "body1" color = "secondary">CREATING WEALTH THE HALAL WAY</Typography>
            </Box>
        
            <Box sx={{ flexGrow: 1, padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid container spacing={2}>
                    {bottomBoxes.map((box, index) => (
                        <Grid item xs={4} key={index}>
                        <SmallBox>
                            {box.icon}
                        <Typography variant="body1">{box.text}</Typography>
                        </SmallBox>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
          </Stack>
      </Box>
    </Box>
  );
};

export default InvestmentSolutionSection;
