import SectionOne from "./SectionOne";
import InvestorNavSection from "./InvestorNavSection";
import AboutUsSection from "./AboutUsSection";
import InvestmentSolutionSection from "./InvestmentSolutionSection";
import AppDownloadSection from "./AppDownloadSection";
import FinancialIntelligenceSection from "./FinancialIntelligenceSection";
import NewsAndEventSection from "./NewsAndEventSection";
import ContactDetails from "./ContactDetails";
import { Stack, Container } from "@mui/material";
import ReportInputDetails from "./ReportInputDetails";
import Footer from "./Footer";

const HomePage = () => {
    return (
        <>
            <SectionOne />
            <InvestorNavSection />
            <AboutUsSection />
            <InvestmentSolutionSection />
            <AppDownloadSection />
            <FinancialIntelligenceSection />
            <NewsAndEventSection />
            <Container>
                <Stack direction={{ sm: 'column', md: 'row' }}>
                    <ContactDetails />
                    <ReportInputDetails />
                </Stack>
            </Container>
            <Footer />
        </>
    );
}

export default HomePage;
