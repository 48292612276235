// FormIdentityDetails.js
import React from 'react';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';

const FormIdentityDetails = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGenderChange = (e) => {
    setFormData({ ...formData, gender: e.target.value });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography component="h2" variant="h6">
        Identity Details
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        autoComplete="given-name"
        value={formData.firstName}
        onChange={handleChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        autoComplete="family-name"
        value={formData.lastName}
        onChange={handleChange}
      />
      <Typography variant="subtitle1" gutterBottom>
        Gender
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.gender === 'male'}
            onChange={handleGenderChange}
            name="gender"
            value="male"
          />
        }
        label="Male"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.gender === 'female'}
            onChange={handleGenderChange}
            name="gender"
            value="female"
          />
        }
        label="Female"
      />
      <Box>
        {formData.gender === 'male' && (
          <>
            <Typography component="h2" variant="h6">
              Guarantor/Witness
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorName"
              label="Full Name"
              name="guarantorName"
              value={formData.guarantorName}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorPhone"
              label="Phone Number"
              name="guarantorPhone"
              value={formData.guarantorPhone}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorAddress"
              label="Address"
              name="guarantorAddress"
              value={formData.guarantorAddress}
              onChange={handleChange}
            />
          </>
        )}
        {formData.gender === 'female' && (
          <>
            <Typography component="h2" variant="h6">
              Guarantor/Witness 1
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorName1"
              label="Full Name"
              name="guarantorName1"
              value={formData.guarantorName1}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorPhone1"
              label="Phone Number"
              name="guarantorPhone1"
              value={formData.guarantorPhone1}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorAddress1"
              label="Address"
              name="guarantorAddress1"
              value={formData.guarantorAddress1}
              onChange={handleChange}
            />
            
            <Typography component="h2" variant="h6">
              Guarantor/Witness 2
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorName2"
              label="Full Name"
              name="guarantorName2"
              value={formData.guarantorName2}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorPhone2"
              label="Phone Number"
              name="guarantorPhone2"
              value={formData.guarantorPhone2}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="guarantorAddress2"
              label="Address"
              name="guarantorAddress2"
              value={formData.guarantorAddress2}
              onChange={handleChange}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default FormIdentityDetails;
