import { Box, TextField, Container, Typography, Grid, FormControlLabel, Checkbox, FormGroup, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)({
    color: 'white',
    marginBottom: '8px',
    fontSize: '16px',
});

const StyledTextField = styled(TextField)({
    backgroundColor: 'white',
    borderRadius: '5px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiOutlinedInput-input': {
        fontSize: '14px',
        height: '14px',
    },
});

const StyledButton = styled(Button)({
    color: 'white',
    padding: '10px 20px',
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'white',
    '&.Mui-checked': {
        color: theme.palette.otherColor.main,
    },
}));

const fields = [
    { label: "First Name", id: "first-name" },
    { label: "Email Address", id: "email-address" },
    { label: "Phone Number", id: "phone-number" },
    { label: "Last Name", id: "last-name" },
];

const checkboxes = [
    "Weekly Market Update",
    "Weekly Market Report Podcast",
    "Quarterly Market Report",
    "Investor Quarterly Publication",
    "Investment Series"
];

const ReportInputDetails = () => {
    return (
        <Container sx={{ backgroundColor: '#E59139', py: 6 }}
        data-aos="fade-up"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine">
            <Grid container spacing={2}>
                {fields.map((field, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Box>
                            <StyledTypography>{field.label}</StyledTypography>
                            <StyledTextField
                                id={field.id}
                                variant="outlined"
                                fullWidth
                            />
                        </Box>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <StyledTypography sx={{ fontSize: '24px', marginTop: '16px' }}>
                        Which of our Investment Reports are you interested in?
                    </StyledTypography>
                    <FormGroup>
                        {checkboxes.map((label, index) => (
                            <FormControlLabel
                                key={index}
                                control={<StyledCheckbox />}
                                label={<Typography sx={{ color: 'white' }}>{label}</Typography>}
                            />
                        ))}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <StyledButton variant="text" sx={{ mr: 2, fontWeight: 600 }}>Send</StyledButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReportInputDetails;
